// eslint-disable-next-line max-len, import-newlines/enforce
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild, computed, signal } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {  Observable, Subject, takeUntil } from 'rxjs';
import {
  ForgotPasswordChangePasswordDto,
  ForgotPasswordUserVerificationResponse,
  ForgotPasswordVerifyDto
} from '../../../shared/models/forgot-password.model';
import { ApiError } from '../../../shared/models/http.model';
import { LocalizedMessages } from '../../../shared/models/i18n.model';
import { CommonService } from '../../../shared/service/common/common.service';
import { ModalService } from '../../../shared/service/modal/modal.service';
import { UserService } from '../../../shared/service/user/user.service';
import { langxObj } from './forgot-password_langx';
import { OtpApiErrors, OtpStage } from '../../../shared/models/otp.model';
import { OtpService } from '../../../shared/service/otp/otp.service';

/**
 * Represents the screens of ForgotPassword
 */
export enum ForgotPasswordStage {
  UserVerification,
  OtpChannelSelection,
  OtpVerification,
  ChangePassword
}

export enum OtpChannel {
  MOBILE,
  EMAIL
}

export enum ErrorCodes {
  UserNotExists = 'error.forgot-password.user.not-exists',
  VerificationDidNotMatch = 'error.forgot-password.verification-code.not-match',
  OtpMaxRetriesReached = 'error.otp-validation.max-retries-reached'
}

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.scss'
})

export class ForgotPasswordComponent implements OnInit, OnDestroy, AfterViewInit {


  constructor(
    private userService: UserService,
    private modalService: ModalService,
    private commonService: CommonService,
    private otpService: OtpService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private renderer: Renderer2,
  ) {

    this.mediaServer$ = this.commonService.mediaServer$;
    this.commonService.mediaServer$.subscribe(mediaServer => this.mediaServer = mediaServer);

    this.commonService.currentLanguage$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (lang) => {
          this.langx = langxObj[lang];
        }
      });

    this.initializeUserVerificationForm();
    this.initializeChangePasswordForm();
  }

  // make the enum accessible in the html template
  readonly ForgotPasswordStage = ForgotPasswordStage;
  readonly OtpChannel = OtpChannel;

  mediaServer$: Observable<string>;
  destroy$: Subject<void> = new Subject();

  verificationCodeImageBase64: string = '';
  currentStage: ForgotPasswordStage = ForgotPasswordStage.UserVerification;

  // list of observables used for autofocus of otp input field
  userVerificationForm!: FormGroup;
  changePasswordForm!: FormGroup;
  langx: LocalizedMessages = {};

  apiErrors$ = signal([] as ApiError[]);

  isVerificationCodeDidNotMatch = signal(true);

  isCredentialsInvalid = computed(() => 
    this.apiErrors$().find( apiError => apiError.code == ErrorCodes.UserNotExists ));

  isVerificationCodeInvalid = computed(() => this.isVerificationCodeDidNotMatch() && 
    this.apiErrors$().find( apiError => apiError.code == ErrorCodes.VerificationDidNotMatch ));

  isLoginNameValid = computed(() => 
    this.apiErrors$().find( apiError => apiError.field === 'loginName'));

  isRealNameValid = computed(() => this.isCredentialsInvalid() && 
    this.apiErrors$().find( apiError => apiError.field === 'realName'));

  isOtpMaxRetriesReached = computed(() => 
    this.apiErrors$().find( apiError => apiError.code == ErrorCodes.OtpMaxRetriesReached ));

  userVerificationResponse: ForgotPasswordUserVerificationResponse | undefined;
  selectedOtpChannel: any;
  isPasswordShow: boolean = false;
  isConfirmPasswordShow: boolean = false;
  loginNameControl!: AbstractControl;
  realNameControl!: AbstractControl;
  verificationCodeControl!: AbstractControl;
  passwordControl!: AbstractControl;
  confirmPasswordControl!: AbstractControl;
  isChangePasswordValidationShow: boolean = false;
  timeRemaining$ = new Observable<number>();
  otpErrorMessage: string = '';
  shouldShowOtpErrorMessage: boolean = false;
  mediaServer: string = '';
  isValidLoginName: boolean = false;
  isValidRealName: boolean = false;
  isValidVerificationCode: boolean = false;

  @ViewChild('loginNameInputField') loginNameInput!: ElementRef;
  @ViewChild('realNameInputField') realNameInput!: ElementRef;
  @ViewChild('verificationCodeInputField') verificationCodeInput!: ElementRef;

  forgotPasswordToken: string = '';

  ngOnInit(): void {
    this.fetchVerificationCode();
    this.commonService.showBackButtonUntil(this.destroy$);
    this.commonService.preloadImages([
      this.mediaServer + '/zeta/common/icon_password-visible.png',
      this.mediaServer + '/zeta/common/icon_password-hidden.png',
      this.mediaServer + '/zeta/user/forgot-password/icon_phone.png',
      this.mediaServer + '/zeta/user/forgot-password/icon_phone-disabled.png',
      this.mediaServer + '/zeta/user/forgot-password/icon_envelope.png',
      this.mediaServer + '/zeta/user/forgot-password/icon_envelope-disabled.png'
    ]);
  }

  ngAfterViewInit(): void {
    this.switchStage(ForgotPasswordStage.UserVerification);
    this.addFocusEventListeners();
  }

  addFocusEventListeners() {
    this.addFocusListener(this.loginNameInput, 'loginName');
    this.addFocusListener(this.realNameInput, 'realName');
    this.addFocusListener(this.verificationCodeInput, 'verificationCode');
  }

  addFocusListener(element: ElementRef, field: string) {
    this.renderer.listen(element.nativeElement, 'focus', () => {
      const control = this.userVerificationForm.get(field);
      if (control) {
        this.reactivateValidator(control, field);
      }
    });
  }

  onFieldFocus(field: string) {
    const control = this.userVerificationForm.get(field);
    if (control) {
      this.reactivateValidator(control, field);
    }
  }

  fetchVerificationCode() {
    this.verificationCodeImageBase64 = '';
    this.userService.fetchForgotPasswordVerificationCodeImage().subscribe(ret => {
      if (ret.isSuccess) {
        this.verificationCodeImageBase64 = ret.data.verificationCodeImageBase64;
      }
    });
  }

  onRefreshVerificationCodeIconClick() {
    this.verificationCodeControl.setValue('');
    this.isVerificationCodeDidNotMatch.set(true);
    this.fetchVerificationCode();
  }

  onPageHeaderBackIconClick() {
    this.currentStage -= 1;
    this.clearAllValidators();
    this.switchStage(this.currentStage);
  }

  clearAllValidators() {
    Object.keys(this.userVerificationForm.controls).forEach(field => {
      const control = this.userVerificationForm.get(field);
      control?.clearValidators();
      control?.updateValueAndValidity();
    });
  }

  reactivateValidator(control: any, field: string) {
    switch (field) {
      case 'loginName':
        control.setValidators([Validators.required, this.noWhiteSpaceValidator]);
        break;
      case 'realName':
        control.setValidators([Validators.required, this.noWhiteSpaceValidator]);
        break;
      case 'verificationCode': {
      
        control.setValidators([Validators.required, 
          Validators.pattern('^[0-9]{4}$'), this.noWhiteSpaceValidator]);
        break;
      }
    }
    control.updateValueAndValidity();
    
  }

  switchStage(stage: ForgotPasswordStage) {
    this.currentStage = stage;
    this.clearApiErrors();
    
    if ( this.currentStage == ForgotPasswordStage.OtpChannelSelection ||
      this.currentStage == ForgotPasswordStage.OtpVerification
    ) {
      this.otpService.showComponent();
    } else {
      this.otpService.hideComponent();
    }

    switch (this.currentStage) {
      case ForgotPasswordStage.UserVerification: {
        this.commonService.showBackButtonUntil(this.destroy$);
        this.isVerificationCodeDidNotMatch.set(true);
        this.userVerificationForm.reset();
        this.clearAllValidators();

        break;
      }

      case ForgotPasswordStage.OtpChannelSelection: {
        this.timeRemaining$ = new Observable<number>();
        this.otpErrorMessage = '';
        this.shouldShowOtpErrorMessage = false;
        this.commonService.showBackButtonUntil(this.destroy$, true)
          .subscribe(() => {
            this.onPageHeaderBackIconClick();
          });

        this.selectedOtpChannel = null;
        this.otpService.switchStage(OtpStage.CHANNEL_SELECTION);
        break;
      }

      case ForgotPasswordStage.OtpVerification: {
        this.commonService.showBackButtonUntil(this.destroy$, true)
          .subscribe(() => {
            this.onPageHeaderBackIconClick();
          });

        this.otpService.switchStage(OtpStage.OTP_VALIDATION);
        break;
      }

      case ForgotPasswordStage.ChangePassword: {
        this.commonService.showBackButtonUntil(this.destroy$, true)
          .subscribe(() => {
            this.onPageHeaderBackIconClick();
          });

        this.isVerificationCodeDidNotMatch.set(true);
        this.changePasswordForm.reset();
        break;
      }
    }
  }

  initializeUserVerificationForm() {
    this.userVerificationForm = this.formBuilder.group({
      loginName: ['', [Validators.required, this.noWhiteSpaceValidator]],
      realName: ['', [Validators.required, this.noWhiteSpaceValidator]],
      verificationCode: ['', [Validators.required, Validators.pattern('^[0-9]{4}$'), this.noWhiteSpaceValidator]]
    });

    this.loginNameControl = this.userVerificationForm.get('loginName')!;
    this.realNameControl = this.userVerificationForm.get('realName')!;
    this.verificationCodeControl = this.userVerificationForm.get('verificationCode')!;

    this.realNameControl.statusChanges.subscribe(status => {
      if(status == 'INVALID') {
        this.clearApiErrors('realName');
      }
    });
  } 

  initializeChangePasswordForm() {
    const passwordFormatRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#@!~%*&^])[A-Za-z\d#@!~%*&^]{6,30}$/;
    this.changePasswordForm = this.formBuilder.group({
      password: ['', [
        Validators.required,
        Validators.pattern(passwordFormatRegex)
      ]],
      confirmPassword: ['', Validators.required]
    });

    this.passwordControl = this.changePasswordForm.get('password')!;
    this.confirmPasswordControl = this.changePasswordForm.get('confirmPassword')!;
  }

  onValidateUserVerificationClick() {
    const forgotPasswordDto: ForgotPasswordVerifyDto = this.userVerificationForm.value;
    this.validateUserVerification(forgotPasswordDto);
  }

  validateUserVerification(forgotPasswordDto: ForgotPasswordVerifyDto) {
    this.commonService.showLoading();
    this.userService.validateForgotPasswordUserVerification(forgotPasswordDto).subscribe(response => {
      if (response.isSuccess) {
        this.userVerificationResponse = response.data;
        this.isVerificationCodeDidNotMatch.set(false);

        if (this.userVerificationResponse?.emailVerified || this.userVerificationResponse?.mobileVerified) {
          this.forgotPasswordToken = this.userVerificationResponse.forgotPasswordToken;

          const otpChannelInfo = {
            email: this.userVerificationResponse.email,
            emailEnabled: this.userVerificationResponse.emailVerified,
            mobile: this.userVerificationResponse.mobile,
            mobileEnabled: this.userVerificationResponse.mobileVerified
          };

          this.otpService.startWithChannelSelection(otpChannelInfo);
        
        } else {
          this.modalService.showInformation(this.langx['FORGOT_PASSWORD_NO_OTP_CHANNEL']);
        }

        this.onRefreshVerificationCodeIconClick();
      } else {
        this.apiErrors$.set(response.errors ?? []);

        const hasCaptchaVerificationErrorCodes = response.errors?.some(error => error.field == 'verificationCode');
        if (!hasCaptchaVerificationErrorCodes) {
          this.onRefreshVerificationCodeIconClick();
        }
      }
      this.commonService.hideLoading();
    });
  }

  onForgotPasswordSubmitClick() {
    const forgotPasswordChangePasswordDto: ForgotPasswordChangePasswordDto = this.changePasswordForm.value;
    forgotPasswordChangePasswordDto.forgotPasswordToken = this.userVerificationResponse?.forgotPasswordToken ?? '';
    this.changePassword(forgotPasswordChangePasswordDto);
  }

  changePassword(forgotPasswordChangePasswordDto: ForgotPasswordChangePasswordDto) {
    this.commonService.showLoading();
    this.userService.changePassword(forgotPasswordChangePasswordDto).subscribe(response => {
      if (response.hasErrors) {
        this.modalService.showError(response!.errors![0].message);

      } else {
        this.modalService.showSuccess(this.langx['FORGOT_PASSWORD_SUCCESSFUL_UPDATED_PASSWORD'])
          .subscribe(() => {
            this.router.navigate(['../login'], { relativeTo: this.route });
          });

      }
      this.clearApiErrors();
      this.commonService.hideLoading();
    });
  }

  noWhiteSpaceValidator(control: FormControl) {
    return control.value?.trim().length ? null : { 'whitespace': true };
  }

  onToggleShowPassword() {
    this.isPasswordShow = !this.isPasswordShow;
  }

  onToggleShowConfirmPassword() {
    this.isConfirmPasswordShow = !this.isConfirmPasswordShow;
  }

  onChangePasswordFocus() {
    this.isChangePasswordValidationShow = true;
  }

  onChangePasswordBlur() {
    this.isChangePasswordValidationShow = false;
  }

  onLoginNameBlur() {
    setTimeout(() => {
      this.isValidLoginName = true;
      const loginNameControl = this.loginNameControl.get('loginName');
      if (loginNameControl?.pristine && loginNameControl?.value.trim()) {
        this.isValidLoginName = false;
      }
    }, 10);
  }

  onRealNameBlur() {
    setTimeout(() => {
      this.isValidRealName = true;
      const realNameControl = this.realNameControl.get('realName');
      if (realNameControl?.pristine && realNameControl?.value.trim()) {
        this.isValidRealName = false;
      }
    }, 10);
  }

  onVerificationCodeBlur() {
    setTimeout(() => {
      this.isValidVerificationCode = true;
      const VerificationCodeControl = this.verificationCodeControl.get('verificationCode');
      if (VerificationCodeControl?.pristine && VerificationCodeControl?.value.trim()) {
        this.isValidVerificationCode = false;
      }
    }, 10);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  clearApiErrors(field: string = '') {
    if( field == '' ) {
      this.apiErrors$.set([]);
    } else {
      this.apiErrors$.set(
        this.apiErrors$().filter( apiError => apiError.field != field)
      );
    }
  }

  onVerificationCodeKeyUp() {
    if( this.apiErrors$().find( apiError => apiError.code == ErrorCodes.VerificationDidNotMatch)) {
      this.clearApiErrors('verificationCode');
    }
  }

  getApiErrorForField(field: string) {
    return this.apiErrors$().find(apiError => apiError.field == field);
  }

  onRealNameOrLoginNameFieldKeyUp($event: KeyboardEvent) {
    if($event.key.length == 1 || 
      ['Backspace', 'Delete', 'Space'].some( key => key == $event.key)) {
      this.clearApiErrors('realName');
    }
  }

  isPasswordEmpty(): boolean {
    return this.passwordControl.value == null || this.passwordControl.value.length == 0;
  }

  isConfirmPasswordEmpty(): boolean {
    return this.confirmPasswordControl.value == null || this.confirmPasswordControl.value.length == 0;
  }

  onOtpValidationSuccess() {
    this.switchStage(ForgotPasswordStage.ChangePassword);
  }

  onOtpValidationStageChange(otpValidationStage: OtpStage) {
    if (
      otpValidationStage == OtpStage.CHANNEL_SELECTION &&
      this.currentStage != ForgotPasswordStage.OtpChannelSelection
    ) {
      this.switchStage(ForgotPasswordStage.OtpChannelSelection);

    } else if(otpValidationStage == OtpStage.OTP_VALIDATION &&
      this.currentStage != ForgotPasswordStage.OtpVerification
    ) {
      this.switchStage(ForgotPasswordStage.OtpVerification);
    }
  }

  onOtpValidationError(errorCode: string) {
    if(errorCode == OtpApiErrors.MAX_RETRIES_REACHED) {
      this.switchStage(ForgotPasswordStage.UserVerification);
    }
  }

  onOtpValidationSendOtpError() {
    this.switchStage(ForgotPasswordStage.UserVerification);
  }
}

