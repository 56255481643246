import { LocalizedMessagesBundle } from '../../../shared/models/i18n.model';

export const langxObj: LocalizedMessagesBundle = {
  'zh-cn': {
    'FORGOT_PASSWORD_HEADER': '忘记密码',
    'FORGOT_PASSWORD_HEADER_PASSWORD_CHANGE': '修改密码',
    'FORGOT_PASSWORD_USER_VERIFICATION': '输入您的用户名和姓名',
    'FORGOT_PASSWORD_OTP_CHANNEL_SELECTION': '请选择您的密码恢复方法:',
    'FORGOT_PASSWORD_OTP_VERIFICATION_SMS': '请输入收到的验证码。',
    'FORGOT_PASSWORD_OTP_VERIFICATION_EMAIL': '请输入收到的验证码。',
    'FORGOT_PASSWORD_CHANGE_PASSWORD': '请输入新密码，确保与先前的密码不同。',
    'FORGOT_PASSWORD_USERNAME': '请输入用户名',
    'FORGOT_PASSWORD_REALNAME': '请输入真实姓名',
    'FORGOT_PASSWORD_VERIFICATION_CODE': '输入验证码',
    'FORGOT_PASSWORD_NO_OTP_CHANNEL': '您尚未使用手机或邮箱验证账户，请联系在线客服找回密码。',
    'FORGOT_PASSWORD_USER_VERIFICATION_CONTINUE': '下一步',
    'FORGOT_PASSWORD_DIT_NOT_MATCH_PASSWORD': '密码不匹配',
    'FORGOT_PASSWORD_MATCHED_PASSWORD': '密码匹配成功',
    'FORGOT_PASSWORD_CHANGE_PASSWORD_SUBMIT': '提交',
    'FORGOT_PASSWORD_PLACEHOLDER_PASSWORD': '输入您的新密码',
    'FORGOT_PASSWORD_PLACEHOLDER_CONFIRM_PASSWORD': '请输入确认密码',
    'FORGOT_PASSWORD_INVALID_CREDENTIALS': '用户名或者姓名有误，请重新输入！',
    'FORGOT_PASSWORD_INVALID_VERIFICATION_CODE': '验证码输入错误，请重新输入',
    'FORGOT_PASSWORD_VALID_VERIFICATION_CODE': '验证码成功',
    'FORGOT_PASSWORD_USERNAME_EMPTY': '用户名不能为空。',
    'FORGOT_PASSWORD_REALNAME_EMPTY': '真实姓名不能为空。',
    'FORGOT_PASSWORD_VERIFICATION_CODE_EMPTY': '验证码不能为空。',
    'FORGOT_PASSWORD_SUCCESSFUL_UPDATED_PASSWORD': '密码已成功更新。您现在可以使用新密码再次登录',
    'FORGOT_PASSWORD_EMPTY_PASSWORD': '密码不能为空。',
    'FORGOT_PASSWORD_EMPTY_CONFIRM_PASSWORD': '确认密码不能为空。',
    'FORGOT_PASSWORD_FORMAT_INVALID': '密码格式不正确。'
  },
  'en-gb': {
    'FORGOT_PASSWORD_HEADER': 'Forgot Password',
    'FORGOT_PASSWORD_HEADER_PASSWORD_CHANGE': 'Set a new password',
    'FORGOT_PASSWORD_USER_VERIFICATION': 'Enter your username and name',
    'FORGOT_PASSWORD_OTP_CHANNEL_SELECTION': 'Select which contact details should we use ' + 
    'to reset your password',
    'FORGOT_PASSWORD_OTP_VERIFICATION_SMS': 'Enter the OTP sent to your mobile number',
    'FORGOT_PASSWORD_OTP_VERIFICATION_EMAIL': 'Enter the OTP sent to your email address',
    'FORGOT_PASSWORD_CHANGE_PASSWORD': 'Create a new password. Ensure it differs from ' +
    'previous ones for security',
    'FORGOT_PASSWORD_USERNAME': 'Username',
    'FORGOT_PASSWORD_REALNAME': 'Real Name',
    'FORGOT_PASSWORD_VERIFICATION_CODE': 'Verification Code',
    'FORGOT_PASSWORD_NO_OTP_CHANNEL': 'You haven\'t used your mobile or email to verify your account, '
    + 'please use the "CS" function to retrieve your password.',
    'FORGOT_PASSWORD_USER_VERIFICATION_CONTINUE': 'Continue',
    'FORGOT_PASSWORD_DIT_NOT_MATCH_PASSWORD': 'Passwords do not match',
    'FORGOT_PASSWORD_MATCHED_PASSWORD': 'Passwords successfully matched',
    'FORGOT_PASSWORD_CHANGE_PASSWORD_SUBMIT': 'Submit',
    'FORGOT_PASSWORD_PLACEHOLDER_PASSWORD': 'Enter your new password',
    'FORGOT_PASSWORD_PLACEHOLDER_CONFIRM_PASSWORD': 'Confirm password',
    'FORGOT_PASSWORD_INVALID_CREDENTIALS': 'Invalid credentials. Please try again',
    'FORGOT_PASSWORD_INVALID_VERIFICATION_CODE': 'Invalid verification code',
    'FORGOT_PASSWORD_VALID_VERIFICATION_CODE': 'Verification code successful',
    'FORGOT_PASSWORD_USERNAME_EMPTY': 'Username can\'t be empty',
    'FORGOT_PASSWORD_REALNAME_EMPTY': 'Real name can\'t be empty',
    'FORGOT_PASSWORD_VERIFICATION_CODE_EMPTY': 'Verification code field is empty',
    'FORGOT_PASSWORD_SUCCESSFUL_UPDATED_PASSWORD': 'Password successfully updated. You can now '
    + 'login again using your new password.',
    'FORGOT_PASSWORD_EMPTY_PASSWORD': 'Password can not be blank.',
    'FORGOT_PASSWORD_EMPTY_CONFIRM_PASSWORD': 'Confirm password can not be blank.',
    'FORGOT_PASSWORD_FORMAT_INVALID': 'Password format incorrect.'
  }
}
