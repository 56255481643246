import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { HelpCenterItem } from '../../../shared/models/help-center.model';
import { LocalizedMessages } from '../../../shared/models/i18n.model';
import { RegisterDTO } from '../../../shared/models/register.model';
import { AuthService } from '../../../shared/service/auth/auth.service';
import { CommonService } from '../../../shared/service/common/common.service';
import { HelpCenterService } from '../../../shared/service/home/account/help-center/help-center.service';
import {
  TermsAndConditionsService
} from '../../../shared/service/home/account/terms-and-conditions/terms-and-conditions.service';
import { HomeService } from '../../../shared/service/home/home.service';
import { ModalService } from '../../../shared/service/modal/modal.service';
import { PuzzleCaptchaService } from '../../../shared/service/puzzle-captcha/puzzle-captcha.service';
import { UserService } from '../../../shared/service/user/user.service';
import { langxObj } from './register_langx';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrl: './register.component.scss'
})
export class RegisterComponent implements OnInit, OnDestroy {

  private destroy$ = new Subject<void>();
  passwordStrengthDisplayToggle$ = new BehaviorSubject<boolean>(false);
  shouldShowConfirmPasswordText$ = new BehaviorSubject<boolean>(false);

  currentLanguage: string = '';
  langx: LocalizedMessages = {};
  shouldShowLoginNameInfo: boolean = false;
  shouldShowPasswordText: boolean = false;
  shouldShowPasswordStrength: boolean = false;
  shouldShowTerms: boolean = false;
  isLoginNameValid: boolean = true;
  loginNameErrorMsg: string = '';
  registrationForm: FormGroup;
  passwordControl!: AbstractControl;
  confirmPasswordControl!: AbstractControl;
  verificationStub: string = '';
  mediaServer: string = '';
  helpCenterItems: HelpCenterItem[] = [];
  @ViewChild('passwordInputField') passwordElement!: ElementRef;
  preventBlur = false;

  constructor(
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private userService: UserService,
    private modalService: ModalService,
    private router: Router,
    private route: ActivatedRoute,
    private puzzleCaptchaService: PuzzleCaptchaService,
    private authService: AuthService,
    private termsAndConditionsService: TermsAndConditionsService,
    private helpCenterService: HelpCenterService,
    private homeService: HomeService
  ) {
    this.commonService.currentLanguage$
      .pipe(takeUntil(this.destroy$))
      .subscribe(currentLanguage => {
        this.currentLanguage = currentLanguage;
        this.langx = langxObj[this.currentLanguage];
      });

    this.registrationForm = this.formBuilder.group({
      loginName: ['', [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(14),
        this.startsWithLetterOrNumberValidator()
      ]],
      password: ['', [
        Validators.required,
        this.passwordFormatValidator()
      ]],
      confirmPassword: ['', [
        Validators.required,
        this.passwordsMatchValidator()
      ]],
      terms: [false, [
        Validators.requiredTrue
      ]]
    });

    this.passwordControl = this.registrationForm.get('password')!;
    this.confirmPasswordControl = this.registrationForm.get('confirmPassword')!;

    this.commonService.mediaServer$.subscribe(mediaServer => this.mediaServer = mediaServer);
  
  }

  ngOnInit(): void {
    
    const passwordControl = this.registrationFormControl['password'];
    const confirmPasswordControl = this.registrationFormControl['confirmPassword'];
    passwordControl.valueChanges
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(value => {
        if(value !== confirmPasswordControl.value){
          confirmPasswordControl.setErrors({ 'not-matched': { value: confirmPasswordControl.value } });
        } else {
          const errors = confirmPasswordControl.errors;
          if(errors && errors['not-matched']){
            delete errors['not-matched'];
          }
          confirmPasswordControl.setErrors(errors);
          confirmPasswordControl.updateValueAndValidity();
        }
      });
    
    this.userService.showPageBanner();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get registrationFormControl(): { [key: string]: AbstractControl } {
    return this.registrationForm.controls;
  }

  onPasswordFocus(): void {
    this.shouldShowPasswordStrength = true;
  }

  onPasswordBlur(): void {
    setTimeout(() => {
      if (!this.preventBlur) {
        this.shouldShowPasswordStrength = false;
      }
      this.preventBlur = false; // reset the flag
    }, 10);
  }

  onConfirmPasswordBlur(): void {
    if(!this.shouldShowConfirmPasswordText$.value){
      this.confirmPasswordControl.markAsUntouched();
      setTimeout(()=>{
        this.confirmPasswordControl.markAsTouched();
      }, 10);
    }
  }

  onLoginNameFocus(): void {
    this.isLoginNameValid = true;
    this.loginNameErrorMsg = '';
  }

  onLoginNameKeyUp(): void {
    this.shouldShowLoginNameInfoValidation();
  }

  shouldShowLoginNameInfoValidation(){
    const loginNameControl = this.registrationForm.get('loginName');
    
    if (loginNameControl && loginNameControl.value && loginNameControl.value.trim() !== '') {
      this.shouldShowLoginNameInfo = !loginNameControl.valid;

    } else {
      this.shouldShowLoginNameInfo = false;

    }
  }

  onLoginNameBlur(): void {
    setTimeout(()=>{
      if (!this.preventBlur) {
        this.shouldShowLoginNameInfo = false;
      }
      this.preventBlur = false;
    }, 10);
  }

  onToggleShowPasswordType(): void {
    this.passwordStrengthDisplayToggle$.next(true);
    this.shouldShowPasswordText = !this.shouldShowPasswordText;
    this.passwordElement.nativeElement.type = this.shouldShowPasswordText ? 'text' : 'password'; 
    this.preventBlur = true; // set the flag to prevent blur logic
    this.passwordElement.nativeElement.focus();
    this.shouldShowPasswordStrength = true;
  }

  onToggleShowConfirmPasswordType(): void {
    this.shouldShowConfirmPasswordText$.next(!this.shouldShowConfirmPasswordText$.value);
  }

  onToggleShowTerms(): void {
    if(this.shouldShowTerms){
      this.userService.showPageBanner();
      this.shouldShowTerms = false;
    } else {
      this.userService.hidePageBanner();
      this.shouldShowTerms = true;
      this.loadTermsAndConditions();
    }
  }

  onAcceptTerms(): void{
    this.onToggleShowTerms();
    this.registrationFormControl['terms'].setValue(true);
  }

  closePuzzleCaptcha(): void {
    this.router.navigate(['../login'], { relativeTo: this.route });
  }

  isFieldInvalid(field: string): boolean {
    const control = this.registrationFormControl[field];
    return control.touched && control.invalid;
  }

  isFieldEmpty(field: string): boolean {
    const control = this.registrationFormControl[field];
    return control.touched && control.value.length < 1;
  }

  isPasswordMatched(): boolean {
    return !this.isFieldInvalid('confirmPassword') && this.registrationFormControl['confirmPassword'].touched;
  }

  isPasswordFormatInvalid(): boolean {
    return this.isFieldInvalid('password') && this.registrationFormControl['password'].hasError('format-invalid');
  }

  isFormValid(){
    return this.registrationForm.valid;
  }

  startsWithLetterOrNumberValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const valid = /^[A-Za-z0-9][A-Za-z0-9_]*$/.test(control.value);
      return valid ? null : { 'format-invalid': { value: control.value } };
    }
  }

  passwordFormatValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const password = control.value;
      if (!password) {
        return null;
      }

      const passwordFormatRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#@!~%*&^])[A-Za-z\d#@!~%*&^]{6,30}$/;
      const isValid = passwordFormatRegex.test(password);
      if (isValid) {
        return null;
      }
      return { 'format-invalid': { value: control.value } };
    }
  }

  passwordsMatchValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const password = this.registrationForm?.get('password')?.value;
      const confirmPassword = this.registrationForm?.get('confirmPassword')?.value;
      return password !== confirmPassword ? { 'not-matched': { value: control.value } } : null;
    };
  }

  onRegisterSubmitButtonClick() {
    
    if(this.isFormValid()) {
      this.puzzleCaptchaService.showPuzzleCaptcha()
        .pipe(takeUntil(this.destroy$))
        .subscribe(puzzleCaptchaEvent => {
          if (puzzleCaptchaEvent.type == 'onSuccess') {
            this.verificationStub = puzzleCaptchaEvent.verificationStub || '';

            if(!this.isEmptyVerificationStub()) {
              const { ...registerDto } = this.registrationForm.value;
              registerDto.verificationStub = this.verificationStub;
              this.register(registerDto as RegisterDTO)
            }
          }

          if (puzzleCaptchaEvent.type == 'onClose') {
            this.closePuzzleCaptcha();
          }
        });
    }
  }

  register(registerDto: RegisterDTO) {
    this.commonService.showLoading();
    this.userService.register(registerDto).subscribe(response => {
      if (response.isSuccess) {
        this.modalService.showSuccess(this.langx['REGISTER_SUCCESSFUL_TEXT']).subscribe(() => {
          localStorage.setItem('thirdPartyIdentifyId', response.data.thirdPartyIdentifyId);
          localStorage.removeItem('rememberMe');
          localStorage.removeItem('username-credential');
          this.authService.saveAccessToken(response.data.accessToken);
          this.homeService.setIsFromRegisterTrue();
          this.router.navigate(['../../home'], { relativeTo: this.route });
        });
      } else {
        if(response.hasErrors){
          const error = response?.errors?.[0];

          if(error?.code == 'error.register.login-name.already-exists') {
            this.isLoginNameValid = false;
            this.loginNameErrorMsg = error?.message ?? '';
          } else {
            this.modalService.showError(error?.message ?? '');
          }
        }
      }
      
      this.commonService.hideLoading();
    });
  }

  loadTermsAndConditions() {
    this.commonService.showLoading();
    this.termsAndConditionsService.getTermsAndConditions()
      .subscribe(response => {
        const termsAndConditionsData = response.data?.termsAndConditions;
        if(termsAndConditionsData.length > 0) {
          this.helpCenterItems = this.helpCenterService.mapDataToHelpCenterItems(termsAndConditionsData[0].details);
        }
        this.commonService.hideLoading();
      });
  }

  isEmptyVerificationStub() {
    return this.verificationStub.length == 0;
  }

  onClearInput() {
    this.shouldShowLoginNameInfo = true;
    this.preventBlur = true;
    this.registrationForm.get('loginName')?.setValue('');
  }
}
