import { LocalizedMessagesBundle } from '../../../shared/models/i18n.model';

export const langxObj: LocalizedMessagesBundle = {
  'zh-cn': {
    'REGISTER_WITH_WELLBET_TITLE': '以WELLBET账号注册',
    'REGISTER_WITH_WELLBET_DESCRIPTION': '请输入您的WELLBET账号及密码',
    'REGISTER_WITH_WELLBET_TITLE2': '连接 Zeta 账户',
    'REGISTER_WITH_WELLBET_DESCRIPTION2': '输入你的用户名',
    'REGISTER_WITH_WELLBET_LOGIN_NAME_EMPTY': '用户名不能为空',
    'REGISTER_WITH_WELLBET_LOGIN_NAME_PLACEHOLDER': '请输入用户名',
    'REGISTER_WITH_WELLBET_SUBMIT_TEXT': '注册',
    'REGISTER_WITH_WELLBET_LOGIN_TEXT': '登录',
    'REGISTER_WITH_WELLBET_LOGINNAME_FORMAT_INVALID': '【6到14个字母数字字符或下划线】允许 _ 字符。',
    'REGISTER_WITH_WELLBET_LOGINNAME_AVAILABLE': '用户名可以使用',
    'REGISTER_WITH_WELLBET_LOGINNAME_ALREADY_EXISTS': '户名已经被使用了。',
    'REGISTER_WITH_WELLBET_PASSWORD_PLACEHOLDER': '请输入密码',
    'REGISTER_WITH_WELLBET_PASSWORD_EMPTY': '密码不能为空',
    'REGISTER_WITH_WELLBET_CREDENTIALS_INVALID' : '用户名或者密码有误，请重新输入！',
    'REGISTER_WITH_WELLBET_SUCCESSFUL_TEXT': '账户关联成功。点击继续登录。',
    'REGISTER_WITH_WELLBET_LOGIN_EXIST_TEXT': '已有账号?  ',
    'REGISTER_WITH_WELLBET_ACCOUNT_EXIST_TEXT': '您的 Wellbet 账户已注册。请至登录页面继续登录。',
    'REGISTER_WITH_WELLBET_CONTINUE_TEXT':'继续'
  },
  'en-gb': {
    'REGISTER_WITH_WELLBET_TITLE': 'Register using Wellbet Account',
    'REGISTER_WITH_WELLBET_DESCRIPTION': 'Enter your username and password',
    'REGISTER_WITH_WELLBET_TITLE2': 'Connect with Zeta Account',
    'REGISTER_WITH_WELLBET_DESCRIPTION2': 'Enter your username',
    'REGISTER_WITH_WELLBET_LOGIN_NAME_EMPTY': 'Username cannot be empty.',
    'REGISTER_WITH_WELLBET_LOGIN_NAME_PLACEHOLDER': 'Username',
    'REGISTER_WITH_WELLBET_SUBMIT_TEXT': 'Submit',
    'REGISTER_WITH_WELLBET_LOGIN_TEXT': 'Login',
    // eslint-disable-next-line max-len
    'REGISTER_WITH_WELLBET_LOGINNAME_FORMAT_INVALID': '【6 to 14 alphanumeric characters or underscores】. Allowed _ characters.',
    'REGISTER_WITH_WELLBET_LOGINNAME_AVAILABLE': 'Username can be used',
    'REGISTER_WITH_WELLBET_LOGINNAME_ALREADY_EXISTS': 'Username already exists',
    'REGISTER_WITH_WELLBET_PASSWORD_PLACEHOLDER': 'Password',
    'REGISTER_WITH_WELLBET_PASSWORD_EMPTY': 'Password field can\'t be empty.',
    'REGISTER_WITH_WELLBET_CREDENTIALS_INVALID' : 'Invalid login credentials. Please try again.',
    'REGISTER_WITH_WELLBET_SUCCESSFUL_TEXT': 'Account linked, successfully. Click continue to login.',
    'REGISTER_WITH_WELLBET_LOGIN_EXIST_TEXT': 'Already have an account? ',
    // eslint-disable-next-line max-len
    'REGISTER_WITH_WELLBET_ACCOUNT_EXIST_TEXT': 'Your Wellbet account has been already registered before. Please continue to Login.',
    'REGISTER_WITH_WELLBET_CONTINUE_TEXT':'Continue'
  }
}